<template>
  <div class="change-card">
    <h1 class="title">{{$t('Change card')}}</h1>
    <div class="monthly-rent mt-4">
      <div class="monthly-rent-text">
        <p v-if="descriptionMessage" class="montserrat-medium rf-blue-color mt-3 mb-1">{{$t(this.descriptionMessage)}}</p>
      </div>
    </div>
    <form @submit.prevent class="credit-card-form" name="payment-form" id="payment-form" enctype="multipart/form-data" v-if="cardConfig">
      <b-row class="card-box mt-4">
        <b-col cols="auto">
          <FontAwesomeIcon color="#24425B" :icon="['fas', 'credit-card']" class="mb-2"/>
        </b-col>
        <b-col col>
          <div id="xpay-card"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="2">
          <RFButton class="mt-4" label="Change" background="#24425B" color="#fff" @on-click="payment" v-if="!loading"/>
          <RFLoader class="mt-4" :size="30" color="#24425B" v-if="loading"/>
        </b-col>
      </b-row>
    </form>
  </div>
</template>

<script>
import RFButton from '@/components/forms/RFButton'
import RFLoader from '@/components/forms/RFLoader'

import { changeCardService, userService } from '@/services'
import { mapState } from 'vuex'

export default {
  name: 'YourRoofChangeCard',
  components: {
    RFButton,
    RFLoader,
  },
  data() {
    return {
      cardConfig: null,
      loading: false,
      card: null,
      descriptionMessage: null,
      changePressed: false,
    }
  },
  computed: {
    ...mapState({
      reservation: state => state.profileStore.reservation,
      user: state => state.userStore.user
    })
  },
  created() {
    const nexiPayScript = this.nexiPayScript = document.createElement('script')
    nexiPayScript.setAttribute('src', process.env.VUE_APP_PAYMENT_BASE_URL)
    document.head.appendChild(nexiPayScript)
  },
  async beforeMount() {
    this.descriptionMessage = 'Insert credit card to be used for the following payments'

    if(this.reservation==null){
      const currentReservation = await userService.getReservationById(this.user.id,this.$route.params.id)
      this.$store.commit('profileStore/setReservation', currentReservation)
    }

    this.cardConfig = await changeCardService.getChangeCardConfig(this.reservation.id)
    window.addEventListener('load', () => this.initXPay(this.cardConfig))
  },
  mounted() {
    window.addEventListener('XPay_Ready', (event) => this.handlerXPayReady(event))
    window.addEventListener('XPay_Payment_Started', (event) => this.handlerXPayStarted(event))
    window.addEventListener('XPay_Card_Error', (event) => this.handlerXPayError(event))
    window.addEventListener('XPay_Nonce', async (event) => await this.handlerXPayNonce(event))
  },
  beforeDestroy() {
    this.nexiPayScript.parentNode.removeChild(this.nexiPayScript)
    this.$store.commit('profileStore/setReservation', null)
  },
  methods: {
    handlerXPayReady(event) {},
    handlerXPayStarted(event) {},
    handlerXPayError(event) {
      if(this.changePressed && event.detail.errorMessage!==undefined){
        this.changePressed = false
        this.$toasted.error(this.$i18n.t(event.detail.errorMessage))
      }
    },
    async handlerXPayNonce(event) {
      this.loading = true

      const { detail: response } = event
      const { dettaglioCarta, idOperazione, esito, xpayNonce, timeStamp, mac } = response

      if (esito === 'OK') {
        const form = {
          apiKey: this.cardConfig.apiKey,
          codiceTransazione: this.cardConfig.codiceTransazione,
          importo: this.cardConfig.amount,
          divisa: this.cardConfig.currency,
          xpayNonce,
          idOperazione,
          timeStamp,
          esito,
          mac,
          dettaglioCarta,
          numeroContratto: this.reservation.numeroContratto,
        }

        const confirm = await changeCardService.confirmChangeCard(this.reservation.id, form)

        if (confirm.esito === 'KO') {
          this.$toasted.error(this.$i18n.t(`Card could not be changed. Please try again`))
          window.setTimeout(() => window.location = this.$route.path, 1000)
          return false
        }

        this.$store.commit('profileStore/setReservation', null)
        this.$toasted.success(this.$i18n.t(`Card successfully changed!`))
        this.$router.push({ name: 'your-roof' })
        this.loading = false
      } else {
        this.loading = false
      }
    },
    initXPay(cardConfig) {
      const { apiKey, environment, amount, codiceTransazione, currency, timeStamp, mac, language } = cardConfig
      const url = `${process.env.VUE_APP_BASE_URL}/changecard/access/${this.reservation.id}`
      const urlBack = `${process.env.VUE_APP_BASE_URL}/changecard/confirm/${this.reservation.id}`

      XPay.init()

      const config = {
        baseConfig: {
          apiKey,
          enviroment: environment//=XPay.Environments.INTEG
        },
        paymentParams: {
          amount,
          transactionId: codiceTransazione,
          currency,
          timeStamp,
          mac,
          url,
          urlBack,
        },
        customParams: {
        },
        language,//=XPay.LANGUAGE.ITA,
        serviceType: 'paga_multi', // previous value was paga_oc3d, but it has problems with online payment company NEXI
        requestType: "RC"
      }

      XPay.setConfig(config)
      XPay.setInformazioniSicurezza({})

      const style = {
        common: {
          color: '#000',
          ':hover': {
            color: '#000'
          }
        },
        error: {
          color: '#FF5A5F',
        },
        correct: {
          color: '#5CB85C',
        }
      }

      const card = this.card = XPay.create(XPay.OPERATION_TYPES.CARD, style)
      card.mount('xpay-card')
    },
    payment() {
      this.changePressed = true
      XPay.createNonce('payment-form', this.card)
    }
  }
}
</script>

<style lang="scss" scoped>
.change-card {
  min-height: 80vh;
  margin-top: 30px;

  .title {
    @include font-style($montserrat, 'medium', $font-20);
    color: $dark-gray;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: .5px solid $dark-gray;
  }
  .credit-card-form {
    .card-box {
      margin: 0 5px;
      padding-top: 10px;
      border: 1px solid $dark-blue;
    }
  }
  .monthly-rent-text {
    @include font-style($montserrat-bold, 'bold', $font-14);
    color: var(--color);

    .mail {
      color: var(--color);
    }
  }
}

@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {
  .change-card {
    padding-left: 50px;
    margin-top: 0;
  }
}
</style>
